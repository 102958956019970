@charset "utf-8";
@use '../foundation/base' as base;
@use '../foundation/mixin' as mixin;

/*-------------------
	footer
-------------------*/
.p-breadcrumbBox {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: base.$pagetop_size;
	padding: 0 base.$spYohaku;
	background: base.$gray-f7;
	font-size: 12px;
	line-height: 1.4;
	color: base.$black;
	text-transform: capitalize;
	position: relative;
	.pagecode-index & {
		background: base.$gray-fa;
	}
	@include base.mq() {
		min-height: base.$pagetop_sizeSP;
		font-size: 10px;
		.pagecode-index & {
			background: #FFF;
		}
	}
}
.c-breadcrumbList {
	display: flex;
	flex-wrap: wrap;
	width: base.$con_width;
	max-width: 100%;
	margin: 0 auto;
	&__item {
		&:not(:last-child) {
			padding-right: 21px;
			margin-right: 15px;
			position: relative;
			&::after {
				content: '';
				width: 6px;
				height: 9px;
				background: url(../img/common/arrow_bread.svg) center no-repeat;
				background-size: auto 100%;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
		}
		& > a {
			text-decoration: underline;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
		}
	}
}
.p-pagetop {
  $block: #{&};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: base.$pagetop_size;
	height: base.$pagetop_size;
	border: none;
	background: url(../img/common/arrow_pagetop.svg) center no-repeat;
	background-size: calc(20 / 60 * 100%) auto;
  background-color: base.$gray-aa;
	font-size: 0;
	opacity: 0;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 100;
	@include mixin.transition;
  @include mixin.opacityBtn;
	&.fixed {
		position: fixed;
		opacity: 1;
		&:hover {
			opacity: mixin.$hoverOpacity;
		}
	}
	&.stop {
		position: absolute;
		opacity: 1;
		&:hover {
			opacity: mixin.$hoverOpacity;
		}
	}
	@include base.mq() {
		width: base.$pagetop_sizeSP;
		height: base.$pagetop_sizeSP;
	}
}

.l-footer {
	padding: 20px base.$spYohaku 40px;
	background: base.$gray-e5;
	font-size: 1.2rem;
	line-height: calc(26 / 12);
	@include base.mq() {
		padding: 30px base.$spYohaku;
	}
	@include base.mq('xs') {
		font-size: inherit;
		line-height: 2.2;
		text-align: center;
	}
  &__inner {
    display: flex;
    align-items: flex-start;
    width: base.$min_width;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    @include base.mq() {
      flex-wrap: wrap;
      justify-content: space-between;
    }
		@include base.mq('xs') {
			flex-direction: column;
		}
  }
  &__left,
  &__nav,
  &__right {
    width: 33.33%;
    @include base.mq() {
      padding: 1em;
      margin: 0;
    }
		@include base.mq('xs') {
			width: 100%;
      padding: 0;
    }
  }
	&__left {
		.c-snsList {
			@include base.mq() {
				display: none;
			}
		}
	}
  &__logo {
    display: flex;
		flex-direction: column;
		margin: 0 0 22px;
		font-family: base.$en_font;
		font-size: 1.8rem;
		font-weight: 500;
		line-height: calc(24 / 18);
		letter-spacing: 0.1em;
		& > img {
			display: inline-block;
			width: 100px;
			max-width: 100%;
			margin: 0 0 10px;
		}
		@include base.mq() {
			margin-bottom: 0;
			& > img {
				margin-bottom: 15px;
			}
		}
		@include base.mq('xs') {
			align-items: center;
			margin-bottom: 30px;
			font-size: 1.4em;
			line-height: calc(19 / 14);
			text-transform: uppercase;
			& > img {
				width: 80px;
			}
		}
  }
  &__nav {
    padding-top: 20px;
		@include base.mq() {
			text-align: center;
		}
		@include base.mq('xs') {
			margin-bottom: 30px;
		}
  }
	&__right {
		padding-top: 20px;
	}
	&__address {
		margin-bottom: 40px;
	}
	&__copyright {
		@include base.mq() {
			margin-top: 40px;
		}
	}
}
