@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;

.c-heading01 {
  $block: #{&};
  @include mixin.heading01;
  margin-bottom: 40px;
  @include base.mq() {
    margin-bottom: 1.4em;
  }
  
  &.gray {
    color: #333;
  }
}
.c-heading02 {
  $block: #{&};
  @include mixin.heading02;
  margin-bottom: 10px;
	&--normal {
		@include mixin.heading02;
		margin-bottom: 20px;
		font-weight: 400;
	}
  @include base.mq() {
    margin-bottom: 12px;
		&--normal {
			margin-bottom: 12px;
			text-align: left;
		}
  }
}
.c-heading03 {
  $block: #{&};
  @include mixin.heading03;
  margin-bottom: 36px;
  @include base.mq() {
    margin-bottom: 26px;
  }
}
