// メディアクエリ設定 / @include mq('s')
// 参考：https://getbootstrap.jp/docs/5.0/layout/breakpoints/
$breakpoints: (
  'xxs': '320',
  'xs': '576',
  's': '768',
  'm': '992',
  'l': '1200',
  // 'xl': '1260', //必要あれば追加
) !default;
@mixin mq($bp1: s) {
  $w1: map-get($breakpoints, $bp1);
  $max1: 'max-width:#{$w1}px';
  @media screen and ($max1) {
    @content;
  }
}

// content_width
$max_width: 1920px;
$min_width: 1100px;
$con_width: 900px;
$con_width-min: 822px;

// スマホ時の余白
$spYohaku: 20px; //左右
$spMargin: 50px; //コンテンツ間
$spMarginSub: 30px; //記事間

// font
$jp_font: 'Noto Sans JP', sans-serif;
$en_font: 'Didot', 'Yu Mincho', 'Yu Mincho Light', 'YuMincho', '游明朝体', '游明朝', serif;

// Didot LT Pro(Headline) : $en_font 600
// Didot LT Pro(Roman) : $en_font 500

// color
$black: #333; //主にMV
$black-3b: #3b4043; //focus時の文字色
$gray-70: #707070; //カテゴリーラベル(HAHA認定資格)
$gray-66: #666; //本文
$gray-aa: #AAA; //ボタン
$gray-bb: #BBB; //border(dashed)
$gray-e4: #e4e4e4; //border(ボタン)
$gray-cd: #cdd6dd; //border(input)、placeholder
$gray-f7: #F7F7F7; //パンくずとメニューリスト
$gray-e5: #E5E5E5; //footer
$gray-98: #98A6B5; //カテゴリーや日付、focus時
$gray-fa: #FAFAFA; //width100vw時の背景色
$gray-e7: #F7F7F7; //membership
$gray-cc: #CCC; //membership

$color-main: $gray-66;
$color-accent: $gray-98;
// $color-line: #00b900;

// base設定
$base_bg: #fff;
$base_ff: $jp_font;
$base_fw: 400;
$base_fsz: 14;
$base_lh: 32;
$base_ltsp: 0;
$base_color: $gray-66;

// セクション間の余白
$mgb_pc: 40px;
$mgb_sp: 30px;

// 高さとかの設定
$gnav_height: 110px; //グロナビの高さ
$gnav_heightSP: 32px; //グロナビの高さ(SP)
$map_height: 400px; //マップの高さ
$map_heightSP: 320px; //マップの高さ(SP)
$pagetop_size: 60px; //pagetopのサイズ
$pagetop_sizeSP: 40px; //pagetopのサイズ(SP)

// drawer
$drawer: (
  'size': 32,
  'bg': $gray-aa,
  'bdc': #FFF,
  'bdr': 0,
);
@mixin drawerPosition {
  top: 0px;
  right: 0px;
}
