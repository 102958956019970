@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;
@use '../../object/component/heading' as heading;

.p-block {
  &:not(:last-child) {
    margin-bottom: base.$mgb_pc;
		@include base.mq() {
			margin-bottom: base.$mgb_sp;
		}
  }
}

.p-freepage {
	font-size: 16px;
	line-height: calc(30 / 16);
	@include base.mq() {
		padding: 0 base.$spYohaku;
		font-size: inherit;
		line-height: 2.2;
	}
  h2:not([class]) {
    @extend .c-heading01;
  }
  h3:not([class]) {
    @extend .c-heading02;
  }
	h4:not([class]) {
    @extend .c-heading03;
  }
  figure {
		display: inline-block;
		margin: 8px;
		& ~ p {
			margin-top: 2em;
		}
		@include base.mq() {
			display: block;
			margin: 10px auto;
			text-align: center;
		}
  }
  strong {
    font-size: 1.2em;
    font-weight: 700;
  }
  p {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
  ul:not([class]) {
    &:not(:last-child) {
      margin-bottom: 54px;
    }
    li {
      padding-left: 1em;
      position: relative;
      &::before {
        content: '・';
        position: absolute;
        top: 0;
        left: 0;
      }
      &:not(:last-child) {
        margin-bottom: 0.4em;
      }
      @include base.mq() {
        margin-bottom: 0.1em;
      }
    }
  }
  ol:not([class]) {
    &:not(:last-child) {
      margin-bottom: 54px;
    }
    li {
      list-style: decimal;
      list-style-position: inside;
      &:not(:last-child) {
        margin-bottom: 0.4em;
      }
      @include base.mq() {
        margin-bottom: 0.1em;
      }
    }
  }
  p + ul,
  p + ol {
    margin-top: -54px;
    margin-left: 1em;
  }
  .c-imgList {
    margin: 5em auto 0;
    &:not(:last-child) {
      margin-bottom: 54px;
    }
  }
}
