@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;

input,
textarea {
  width: 100%;
  max-width: 100%;
  height: 50px;
  padding: 0.5em 10px;
  border: 1px solid base.$gray-cd;
	border-radius: 0;
  background: #fff;
  outline: none;
	&::placeholder {
		color: base.$gray-cd;
	}
	&:focus {
		border-color: base.$gray-98;
		color: base.$black-3b;
	}
	&:invalid {
		border-color: base.$gray-98;
		color: base.$black-3b;
	}
	@include base.mq() {
		height: 40px;
	}
}
textarea {
  width: 100%;
  height: 140px;
	@include base.mq() {
		height: 100px;
	}
}
input[type='submit'] {
  @include mixin.btn;
	cursor: pointer;
}
input[type='checkbox'] {
	appearance: none;
	width: 30px;
	height: 30px;
	border: 1px solid base.$gray-cd;
	cursor: pointer;
	position: relative;
	&::before {
		content: '';
		width: 16px;
		height: 16px;
		background: url(../img/common/icon_check.svg) center no-repeat;
		background-size: auto 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		@include mixin.transition;
	}
	&:checked {
		background: #FFF;
		&::before {
			opacity: 1;
		}
	}
	@include base.mq() {
		width: 24px;
		height: 24px;
		&::before {
			width: 14px;
			height: 14px;
		}
	}
}
input[type='radio'] {
  appearance: none;
  display: inline-block;
  width: calc(30 / 16 * 1em);
  height: calc(30 / 16 * 1em);
  margin-right: 0.4em;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  &::after {
    content: '';
    width: calc(18 / 16 * 1em);
    height: calc(18 / 16 * 1em);
    border-radius: 50%;
    background: base.$color-accent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    @include mixin.transition;
  }
  &:checked {
    &::after {
      opacity: 1;
    }
  }
  & ~ span {
    display: inline-block;
    line-height: calc(30 / 16);
  }
}
