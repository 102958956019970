@charset "utf-8";
@use 'base' as base;
@use 'mixin' as mixin;

* {
  word-wrap: break-word;
}
body {
  overflow-x: hidden;
  // min-width: $min_width;
  min-width: 320px;
  background: base.$base_bg;
  font-family: base.$base_ff;
  font-size: calc(#{base.$base_fsz} / 10 * 1rem);
  font-weight: base.$base_fw;
  line-height: calc(#{base.$base_lh} / #{base.$base_fsz});
  letter-spacing: calc(#{base.$base_ltsp} * 0.001em);
  color: base.$base_color;
  &.scroll-none {
    overflow: hidden;
    &--x {
      overflow-x: hidden;
    }
  }
  @include base.mq('l') {
    overflow-x: hidden;
    position: relative;
  }
	@include base.mq('xs') {
		font-size: 1rem;
		line-height: 2;
	}
}
pre {
  white-space: inherit;
}
input,
textarea {
  font-family: inherit;
}
img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  image-rendering: crisp-edges;
}
a {
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  @include mixin.opacityBtn;
}
img,
a,
button,
select {
  @include mixin.transition;
}
mark {
  display: inline-block;
  background: none;
  color: inherit;
  box-shadow: 0 -0.8em 0 base.$color-accent inset;
}
ul {
  list-style-type: none;
}
::selection {
  background: rgba(base.$gray-98, 0.6);
  color: #fff;
}
