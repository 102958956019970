@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;


.p-contact {
	margin-bottom: 80px;
}

/*-------------------
	メールフォーム
-------------------*/
.c-mailform {
	$block: #{&};
	font-size: calc(16 / 14 * 1em);
	@include base.mq() {
		font-size: inherit;
	}
	&__listItem {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		&:not(:last-child) {
			margin-bottom: calc(25 / 16 * 1em);
		}
		@include base.mq() {
			flex-direction: column;
			&:not(:last-child) {
				margin-bottom: 2em;
			}
		}
	}
	&__label {
		flex: 0 0 auto;
		width: 12em;
		&--req {
			color: base.$gray-98;
		}
		@include base.mq() {
			width: 100%;
			margin-bottom: 1em;
		}
	}
	&__detail {
		width: calc(602 / 822 * 100%);
		input, textarea, select {
			& ~ div {
				margin-top: calc(16 / 12 * 1em);
				font-size: calc(12 / 16 * 1em);
				line-height: 1.2;
				// display: none;
				// opacity: 0;
				@include mixin.transition;
			}
			// &:invalid ~ div {
			// 	display: block;
			// 	opacity: 1;
			// }
		}
		@include base.mq() {
			width: 100%;
		}
	}
	&__agree {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: calc(40 / 12 * 1em) auto 0;
		font-size: calc(12 / 16 * 1em);
		&--txt {
			margin-left: 2em;
			a {
				text-decoration: underline;
				&:hover {
					opacity: 1;
					text-decoration: none;
				}
			}
		}
		@include base.mq() {
			margin-top: 2em;
			font-size: inherit;
		}
	}
	&__btnWrap {
		width: 236px;
		max-width: 100%;
		margin: 30px auto 0;
		@include base.mq() {
			width: 180px;
		}
	}
}
