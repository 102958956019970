@charset "utf-8";
@use '../foundation/base' as base;
@use '../foundation/mixin' as mixin;

// main
.l-main {
	$block: #{&};
  padding: 60px 0 148px;
  &--2column {
    display: flex;
    justify-content: space-between;
		width: calc(#{base.$min_width} + calc(#{base.$spYohaku} * 2));
		max-width: 100%;
		padding: 60px #{base.$spYohaku} 80px;
		margin: 0 auto;
		.pagecode-blog_detail & {
			padding-bottom: 0;
		}
  }
  .pagecode-index & {
    padding: 52px 0 0;
  }
  @include base.mq('l') {
    overflow-x: hidden;
  }
	@include base.mq() {
		&, &--2column {
			padding: base.$spMargin 0 calc(#{base.$spMargin} * 2);
		}
		&--2column {
			flex-direction: column;
			align-items: center;
		}
		.pagecode-index & {
			padding: base.$spMargin 0 0;
		}
	}
	&__contents {
		#{$block}--2column & {
			width: 740px;
			max-width: calc(740 / 1100 * 100%);
			@include base.mq() {
				width: 100%;
				max-width: 100%;
			}
		}
	}
	&__sidebar {
		#{$block}--2column & {
			width: 320px;
			max-width: calc(320 / 1100 * 100%);
			@include base.mq() {
				width: 100%;
				max-width: 100%;
				padding: 0 #{base.$spYohaku};
				margin-top: base.$spMargin;
			}
		}
	}
}

// contents
@mixin contentsBlock($width: 'normal') {
  @if $width == 'min' {
    width: base.$con_width-min;
  } @else {
    width: base.$con_width;
  }
  max-width: calc(100% - calc(#{base.$spYohaku} * 2));
  margin: 0 auto;
}
.l-contents {
  &__block {
    @include contentsBlock;
		// &:not(:last-child) {
		// 	margin-bottom: 80px;
		// }
    &--min {
      @include contentsBlock('min');
    }
    &--break {
      @include base.mq() {
        overflow: hidden;
				// &:not(:last-child) {
				// 	margin-bottom: 40px;
				// }
      }
    }
  }
}

// breadcrumb
.l-breadcrumb {
  $block: #{&};
  @include contentsBlock();
  display: flex;
  font-family: base.$jp_font;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0;
  color: #666;
  @include base.mq('xs') {
    font-size: 10px;
  }
  &__item {
    &:not(:last-child) {
      padding-right: 2em;
      margin-right: 1em;
      position: relative;
      &::after {
        content: '';
        width: 0.5em;
        height: calc(9 / 12 * 1em);
        background: url(../img/common/arrow_panz.svg) center no-repeat;
        background-size: auto 100%;
        position: absolute;
        top: calc(4 / 12 * 1em);
        right: 0;
      }
    }
  }
  &__link {
    font-weight: 400;
    text-decoration: underline;
    &:hover {
      opacity: 1;
      text-decoration: none;
    }
    #{$block}__item:first-child & {
      padding-left: 2.1em;
      position: relative;
      &::before {
        content: '';
        width: calc(16 / 12 * 1em);
        height: calc(14 / 12 * 1em);
        background: url(../img/common/icon_home.svg) center no-repeat;
        background-size: auto 100%;
        position: absolute;
        top: calc(2 / 12 * 1em);
        left: 0;
      }
    }
  }
}
