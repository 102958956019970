@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;

// 段落 + 段落
.u-txt {
  & + & {
    margin-top: 2em;
  }
  & + .c-note {
    margin-top: 1em;
  }
  &.center {
    text-align: center;
    // @include base.mq() {
    //   text-align: left;
    // }
  }
}

// PC・SP切り替え
br.u-sp,
span.u-sp {
  display: none;
}
span.u-dib,
span.u-dib--sp {
  display: inline-block;
}
.u-spBlock {
  display: none;
}
@include base.mq() {
  br.u-pc,
  span.u-pc {
    display: none;
  }
	div.u-sp,
  br.u-sp,
  span.u-sp {
    display: inline-block;
  }
  .u-spBlock {
    display: block;
  }
  .u-pcBlock {
    display: none;
  }
}

// 色
.u-red {
  color: #cc0000;
}

// アンカーリンク
.u-anchorLink {
  padding-top: calc(#{base.$gnav_height} + 0px);
  margin-top: -#{base.$gnav_height};
	@include base.mq('m') {
		padding-top: 0;
		margin-top: 0;
	}
}

// 画像
.u-figure {
  text-align: center;
  &__caption {
    margin-top: 2.2em;
    text-align: center;
  }
}

//マップ
.u-map {
  width: 100%;
  height: base.$map_height;
  iframe {
    width: 100%;
    height: 100%;
  }
  @include base.mq() {
    height: base.$map_heightSP;
  }
}

// その他
.u-note {
  padding-left: 1em;
  position: relative;
  &::before {
    content: '※';
    position: absolute;
    top: 0;
    left: 0;
  }
}
.u-date {
  display: block;
  text-align: center;
}
.u-square {
  padding-left: 1.5em;
  position: relative;
  &::before {
    content: '■';
    position: absolute;
    top: 0;
    left: 0;
  }
}
.u-emphasis {
  font-weight: 700;
}
.u-check {
	padding-left: calc(26 / 14 * 1em);
	font-weight: 700;
	position: relative;
	&::before {
		content: '';
		width: calc(16 / 14 * 1em);
		height: calc(16 / 14 * 1em);
		background: url(../img/common/icon_check.svg) no-repeat;
		background-size: auto 100%;
		position: absolute;
		top: 0.6em;
		left: 0;
	}
}
.u-dl {
	display: flex;
	&__dt {
		flex: 0 0 auto;
	}
}
