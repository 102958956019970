@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;


/*-------------------
	ピックアップ
-------------------*/
.c-newsList {
  width: 100%;
  &__item {
    display: flex;
		padding: 20px 0 15px;
		border-bottom: 1px dashed base.$gray-bb;
		line-height: 2;
		&:first-child {
			padding-top: 0;
		}
    @include base.mq() {
      padding: 6px 0;
    }
  }
  &__date {
		flex: 0 0 auto;
    display: inline-block;
		margin-right: 40px;
		@include base.mq() {
      margin-right: 20px;
    }
  }
}

.c-blogPick {
	display: flex;
	flex-wrap: wrap;
	width: calc(1100px + calc(#{base.$spYohaku} * 2));
	max-width: 100%;
	padding: 0 base.$spYohaku;
	margin: 0 auto;
	line-height: calc(24 / 14);
	@include base.mq('xs') {
		flex-direction: column;
	}
	&__item {
		width: calc(266 / 1100 * 100%);
		&:not(:nth-child(4n)) {
			margin-right: calc(12 / 1100 * 100%);
		}
		@include base.mq() {
			width: calc(440 / 900 * 100%);
			&:not(:nth-child(4n)) {
				margin: 0;
			}
			&:nth-child(odd) {
				margin-right: calc(20 / 900 * 100%);
			}
			&:not(:nth-last-child(-n+2)) {
				margin-bottom: calc(20 / 900 * 100%);
			}
		}
		@include base.mq('xs') {
			width: 100%;
			&:nth-child(odd),
			&:not(:nth-last-child(-n+2)) {
				margin: 0;
			}
			&:not(:last-child) {
				margin-bottom: base.$spMarginSub;
			}
		}
	}
	&__img {
		display: block;
		width: 100%;
		text-align: center;
	}
	&__label {
		padding: 10px 0 6px;
		font-size: 1.6rem;
		text-align: center;
		@include base.mq('xs') {
			font-size: 1.4em;
		}
	}
}


/*-------------------
	一覧ページ
-------------------*/
.p-weblog {
	@include base.mq() {
		padding: 0 base.$spYohaku;
	}
	&__pager {
		margin-top: 76px;
		text-align: center;
		@include base.mq() {
			margin-top: 30px;
		}
	}
}
.c-articleList {
	$block: #{&};
	display: flex;
	flex-wrap: wrap;
	font-size: 12px;
	line-height: 2;
	@include base.mq() {
		font-size: inherit;
		line-height: 2.2;
	}
	&__item {
		width: calc(360 / 740 * 100%);
		&:not(:nth-child(2n)) {
			margin-right: calc(20 / 740 * 100%);
		}
		&:not(:nth-last-child(-n+2)) {
			margin-bottom: calc(30 / 740 * 100%);
		}
		@include base.mq() {
			display: block;
			width: 100%;
			&:not(:nth-child(2n)),
			&:not(:nth-last-child(-n+2)) {
				margin: 0 auto;
			}
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
	&__img {
		display: block;
		width: 100%;
		height: auto;
		padding-top: calc(240 / 360 * 100%);
		position: relative;
		img, source {
			@include mixin.ofi;
			&:hover {
				opacity: 1;
			}
		}
		@include base.mq() {
			padding-top: calc(223 / 335 * 100%);
		}
	}
	&__title {
		margin-top: 24px;
		font-size: calc(20 / 12 * 1em);
		font-weight: 400;
		line-height: calc(36 / 20);
		@include base.mq() {
			margin-top: 20px;
			font-size: 1.4em;
			line-height: calc(22 / 14);
		}
	}
	&__txt {
		margin-top: 18px;
		@include base.mq() {
			margin-top: 15px;
		}
	}
	&__bottom {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		font-size: 14px;
		line-height: calc(24 / 14);
		color: base.$gray-98;
		@include base.mq() {
			margin-top: 12px;
			font-size: inherit;
			line-height: 2.4;
		}
	}
	&__time {
		flex: 0 0 auto;
		margin-left: 1em;
	}
}
.c-pager {
	$block: #{&};
	font-size: 16px;
	text-align: center;
	color: #FFF;
	&__list {
		display: inline-flex;
	}
	&__listItem {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		background: base.$gray-aa;
		border: 1px solid base.$gray-aa;
		border-radius: 50%;
		&:not(:last-child) {
			margin-right: 10px;
		}
		&--current {
			@extend #{$block}__listItem;
			background: #FFF;
			color: base.$gray-aa;
			pointer-events: none;
		}
		&--prev, &--next {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 26px;
			height: 40px;
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
		&--omit {
			display: inline-block;
			margin-right: 10px;
		}
	}
}


/*-------------------
	詳細ページ
-------------------*/
.c-weblogDetail {
	margin-bottom: 13px;
	@include base.mq() {
		padding: 0 base.$spYohaku;
	}
	&__img {
		display: block;
		width: 100%;
		margin: 0 auto 40px;
		text-align: center;
	}
	&__title {
		margin-bottom: 4px;
		font-size: 24px;
		line-height: 1.6;
	}
	&__date {
		display: flex;
		flex-wrap: wrap;
		& > *:not(:last-child) {
			margin-right: 20px;
		}
	}
	&__time {
		color: base.$gray-98;
	}
	&__cat {
		display: inline-block;
		text-decoration: underline;
		&:hover {
			opacity: 1;
			text-decoration: none;
		}
	}
}
.c-pagerDetail {
	$block: #{&};
	display: flex;
	justify-content: space-between;
	width: calc(#{base.$con_width-min} + calc(#{base.$spYohaku} * 2));
	max-width: 100%;
	padding: 0 base.$spYohaku;
	margin: 100px auto 80px;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;
	@include base.mq() {
		margin: 80px auto 80px;
		font-size: inherit;
	}
	@include base.mq('xs') {
		font-size: inherit;
	}
	&__item {
		display: flex;
		align-items: center;
		width: calc(400 / 822 * 100%);
		min-height: calc(100 / 16 * 1em);
		padding: 1em 30px;
		border: 1px solid base.$gray-e4;
		&:nth-child(2) {
			justify-content: flex-end;
		}
		&--txt {
			padding-left: 2em;
			position: relative;
			&::before {
				content: '';
				width: 1em;
				height: 1em;
				background: url(../img/common/arrow_prev02.svg) no-repeat;
				background-size: auto 100%;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
				@include mixin.transition-trf;
			}
			#{$block}__item:nth-child(2) & {
				padding: 0 2em 0 0;
				&::before {
					background-image: url(../img/common/arrow_next02.svg);
					left: auto;
					right: 0;
				}
			}
		}
		&:hover {
			#{$block}__item--txt {
				&::before {
					transform: translate(-5px, -50%);
				}
			}
		}
		&:nth-child(2):hover {
			#{$block}__item--txt {
				&::before {
					transform: translate(5px, -50%);
				}
			}
		}
		@include base.mq() {
			padding: 1em;
		}
	}
}
