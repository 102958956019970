@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;
@use '../../foundation/animations' as animations;

/*-------------------
	loading
-------------------*/
// loading
.p-loading {
  $block: #{&};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 20px;
  background: #fff;
	font-family: base.$en_font;
	font-size: 1.4vw;
	letter-spacing: 0.2em;
	color: base.$gray-98;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  opacity: 1;
  transition: all linear 1s;
  filter: blur(0px);
  pointer-events: none;
  &.loaded {
    opacity: 0;
    visibility: hidden;
    filter: blur(15px);
  }
	@include base.mq('m') {
		font-size: 2vw;
	}
	&__txt {
		animation: fuwafuwa 1.6s ease-in-out infinite alternate;
	}
}
