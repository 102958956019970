@charset "utf-8";
@use 'foundation/base' as base;
@use 'foundation/mixin' as mixin;

.p-agreement {
	@include base.mq() {
		line-height: 2.4;
	}
	&__section {
		&:not(:last-child) {
			margin-bottom: 80px;
			@include base.mq() {
				margin-bottom: 60px;
			}
		}
	}
}
.c-agreement {
	&__section {
		&:not(:last-child) {
			margin-bottom: 1em;
		}
	}
}