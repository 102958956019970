@charset "utf-8";
@use '../foundation/base' as base;
@use '../foundation/mixin' as mixin;

/*-------------------
	sidebar
-------------------*/
.l-sidebar {
	&__section {
		&:not(:last-child) {
			margin-bottom: 42px;
		}
	}
	&__label {
		position: relative;
		padding-bottom: 2px;
		margin-bottom: 25px;
		font-family: base.$en_font;
		font-size: 20px;
		font-weight: 600;
		letter-spacing: 0.1em;
		line-height: 1.6;
		text-transform: capitalize;
		&::after {
			content: '';
			width: 60px;
			height: 1px;
			background: base.$gray-bb;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
}