@charset "utf-8";
@use 'foundation/base' as base;
@use 'foundation/mixin' as mixin;

.p-benefits {
	margin: 60px auto 96px;
	.c-hashLinkList {
		margin-bottom: 80px;
	}
	@include base.mq('xs') {
		margin: 40px auto 77px;
		.c-hashLinkList {
			margin-bottom: 40px;
		}
	}
}
.p-qualification {
	margin-bottom: 96px;
	.c-cardList {
		margin-top: 40px;
	}
	@include base.mq('xs') {
		margin-bottom: 60px;
		.c-cardList {
			margin-top: 30px;
		}
	}
}
.p-membership {
	&__section {
		&:not(:last-child) {
			margin-bottom: 58px;
		}
		.c-stepList {
			margin-bottom: 40px;
		}
		@include base.mq() {
			.c-stepList {
				margin-bottom: 30px;
			}
		}
	}
}
