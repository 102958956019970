@charset "utf-8";
@use 'foundation/base' as base;
@use 'foundation/mixin' as mixin;

.p-news {
	margin-bottom: 112px;
	@include base.mq() {
		margin-bottom: base.$spMargin;
	}
}

.p-indexAbout {
	width: calc(#{base.$con_width} + calc(#{base.$spYohaku} * 2));
	max-width: 100%;
	padding: 0 base.$spYohaku;
	margin: 0 auto 120px;
	.c-imgTxtBox {
		&__img {
			width: calc(414 / 900 * 100%);
		}
		&__txt {
			width: calc(440 / 900 * 100%);
		}
	}
	@include base.mq() {
		padding: 18px base.$spYohaku 30px;
		margin-bottom: 25px;
		background: base.$gray-fa;
		.c-imgTxtBox {
			&__img,
			&__txt {
				width: 100%;
			}
			&__img {
				margin-bottom: 20px;
				text-align: center;
			}
		}
	}
}

.p-blogPick {
	margin-bottom: 118px;
	@include base.mq() {
		margin-bottom: base.$spMargin;
	}
}

.p-indexSchool {
	padding: 52px base.$spYohaku 84px;
	background: base.$gray-fa;
	@include base.mq() {
		padding: 25px base.$spYohaku 30px;
	}
}
.c-schoolMenu {
	display: flex;
	width: base.$con_width;
	max-width: 100%;
	margin: 0 auto;
	line-height: calc(26 / 14);
	@include base.mq('xs') {
		flex-direction: column;
	}
	&__item {
		width: calc(440 / 900 * 100%);
		&:not(:nth-child(2n)) {
			margin-right: calc(20 / 900 * 100%);
		}
		@include base.mq('xs') {
			width: 100%;
			&:not(:nth-child(2n)) {
				margin: 0;
			}
			&:not(:last-child) {
				margin-bottom: base.$spMarginSub;
			}
		}
	}
	&__img {
		display: block;
		width: 100%;
		text-align: center;
	}
	&__label {
		padding: 18px 0 12px;
		font-size: 1.8rem;
		font-weight: 700;
		@include base.mq('xs') {
			padding: 1em 0 10px;
			font-size: 1.4em;
		}
	}
}
