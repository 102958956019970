@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;

// imgTxt
.c-imgTxt {
  $block: #{&};
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include base.mq() {
    flex-direction: column;
    align-items: center;
  }
  &__txtBox {
    width: calc(495 / 1050 * 100%);
    @include base.mq() {
      width: 100%;
    }
  }
  &__img {
    width: calc(525 / 1050 * 100%);
    img {
      width: 100%;
    }
    &--reverse {
      @extend #{$block}__img;
      img {
        width: 100%;
      }
    }
    @include base.mq() {
      width: 100%;
      margin-top: 2em;
      text-align: center;
      &--reverse {
        margin: 0 auto 2em;
      }
    }
  }
}

// c-imgTitle
.c-imgTitle {
  $block: #{&};
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 60px;
  }
  &--reverse {
    @extend #{$block};
    flex-direction: row-reverse;
  }
  @include base.mq('xs') {
    display: block;
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
  &__title,
  &__img {
    width: 50%;
    @include base.mq('xs') {
      width: 100%;
    }
  }
  &__title {
    font-size: calc(23 / 14 * 1em);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.3em;
    text-align: center;
    @include base.mq() {
      font-size: 1.5em;
    }
    @include base.mq('xs') {
      padding: 0 0 1em;
    }
  }
}
