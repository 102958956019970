@charset "utf-8";
@use 'foundation/base' as base;
@use 'foundation/mixin' as mixin;

.p-school {
	margin: 60px auto 0;
	@include base.mq() {
		margin-top: 20px;
	}
	&__section {
		&:not(:last-child) {
			padding-bottom: 60px;
			margin-bottom: 60px;
			position: relative;
			&::after {
				content: '';
				width: 150px;
				height: 1px;
				background: base.$gray-cc;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
			}
			@include base.mq() {
				padding-bottom: 40px;
				margin-bottom: 40px;
				&::after {
					width: 100px;
				}
			}
		}
		.u-figure {
			display: block;
			width: 100%;
			margin: 0 auto 20px;
		}
		.c-schoolList {
			margin: 40px auto 0;
			@include base.mq() {
				margin-top: 24px;
			}
		}
	}
}
