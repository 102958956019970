@charset "utf-8";
@use '../foundation/base' as base;
@use '../foundation/mixin' as mixin;

/*-------------------
	drawer
-------------------*/
#nav-drawer {
  position: absolute;
}
#nav-close {
  display: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  background: rgba(base.$black, 0.8);
  opacity: 0;
  transition: 0.3s ease-out;
}
#nav-input {
  display: none;
  &:checked ~ #nav-close {
    display: block;
    opacity: 0.5;
  }
  &:checked ~ #nav-content {
    height: 100%;
    opacity: 1;
    pointer-events: inherit;
  }
  &:checked ~ #nav-open {
    .l-drawerBtn__border {
      &:nth-of-type(1) {
				width: 72%;
        transform: translateY(0.4vw) rotate(-45deg);
      }
      &:nth-of-type(2) {
				width: 72%;
        transform: translateY(-0.4vw) rotate(45deg);
      }
      &:nth-of-type(3) {
				width: 0;
        opacity: 0;
      }
    }
  }
}
#nav-content {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 800;
  width: 100%;
  max-width: 100vw;
  height: 0;
  background: #fff;
  @include mixin.transition;
  pointer-events: none;
  opacity: 0;
  @include base.mq('m') {
    display: block;
    overflow-y: scroll;
  }
}
.l-drawerBtn {
  $block: #{&};
  display: none;
  flex-direction: column;
  box-sizing: border-box;
  width: calc(#{map-get(base.$drawer, 'size')} / 375 * 100vw);
  height: calc(#{map-get(base.$drawer, 'size')} / 375 * 100vw);
  min-width: #{map-get(base.$drawer, 'size')}px;
  min-height: #{map-get(base.$drawer, 'size')}px;
	padding: calc(11 / 375 * 100vw) calc(8 / 375 * 100vw) 0;
  border-radius: #{map-get(base.$drawer, 'bdr')}px;
  background: #{map-get(base.$drawer, 'bg')};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  cursor: pointer;
  position: fixed;
  @include base.drawerPosition;
  z-index: 1001;
  @include mixin.transition;
	&::after {
		content: 'Menu';
		width: 100%;
		font-family: base.$en_font;
		font-size: calc(7 / 375 * 100vw);
		font-weight: 500;
		letter-spacing: 0.1em;
		line-height: 1;
		text-align: center;
		color: #FFF;
		position: absolute;
		top: 5vw;
		left: 0;
	}
  &:hover {
    opacity: 0.9;
  }
	&.drawer {
		justify-content: center;
		align-items: center;
		padding: 0;
		background: none;
		top: calc(13 / 375 * 100vw);
		right: calc(13 / 375 * 100vw);
		&::after {
			opacity: 0;
		}
	}
  @include base.mq('m') {
    display: inline-flex;
  }
  &__border {
    position: relative;
    width: 100%;
    height: 1px;
    transform: translateY(0) scale(1);
    background: #{map-get(base.$drawer, 'bdc')};
    backface-visibility: hidden;
    transition: all ease-out 0.3s;
    top: -0.5vw;
    #{$block}.drawer & {
			background: base.$gray-70;
		}
    &:nth-of-type(2) {
      top: 0;
    }
    &:nth-of-type(3) {
			width: calc(10 / 16 * 100%);
      top: 0.5vw;
    }
  }
}
.l-navContents {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: var(--viewHeight);
    padding: calc(40 / 375 * 100vw) base.$spYohaku;
    margin: 0 auto;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.03em;
  }
}
.l-spMenu {
  width: 100%;
	font-family: base.$en_font;
	font-size: 1.0rem;
	font-weight: 600;
	letter-spacing: 0.1em;
	line-height: 1.4;
	text-align: center;
  &__logo {
    width: 80px;
		max-width: 100%;
    margin: 0 auto 28px;
		text-align: center;
  }
  &__logoLink {
    display: block;
  }
  &__nav {
    width: 100%;
		position: relative;
		padding-bottom: 14px;
		margin-bottom: 25px;
		&::after {
			content: '';
			width: 25px;
			height: 1px;
			background: base.$gray-bb;
			position: absolute;
			bottom: 0;
			left: calc(50% - 12.5px);
		}
  }
	&__sns {
		margin: 40px 0 52px;
	}
	&__sign {
		font-size: 1.2em;
		font-weight: 500;
		line-height: calc(16 / 12);
		text-transform: uppercase;
	}
}
