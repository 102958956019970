@charset "utf-8";
@use '../foundation/base' as base;
@use '../foundation/mixin' as mixin;

/*-------------------
	mv
-------------------*/
.l-mv {
  width: 100%;
  height: 320px;
	border-left: 40px solid #FFF;
	border-right: 40px solid #FFF;
	background: url(../img/index/mv_bg@2x.jpg) center no-repeat;
	background-size: cover;
	font-family: base.$en_font;
  font-size: 2.4rem;
  font-weight: 600;
  text-transform: capitalize;
  line-height: calc(40 / 26);
  letter-spacing: 0.1em;
	color: base.$black;
  position: relative;
  z-index: 1;
  opacity: 0;
  @include mixin.transition;
  &.loaded {
    opacity: 1;
  }
  .pagecode-index & {
    height: 100vh;
		max-height: var(--viewHeight);
    border: 20px solid #FFF;
		font-size: 2.6rem;
		text-transform: inherit;
  }
	.pagecode-about & {
		background-image: url(../img/about/mv_bg@2x.jpg);
		background-position-y: bottom;
	}
	.pagecode-membership & {
		background-image: url(../img/membership/mv_bg@2x.jpg);
	}
	.pagecode-school & {
		background-image: url(../img/school/mv_bg@2x.jpg);
	}
	.pagecode-contact & {
		background-image: url(../img/contact/mv_bg@2x.jpg);
	}
	.pagecode-blog & {
		background-image: url(../img/blog/mv_bg@2x.jpg);
	}
	.pagecode-agreement & {
		background-image: url(../img/agreement/mv_bg@2x.jpg);
		background-position-y: 58%;
	}
  @include base.mq('m') {
		height: calc(212 / 375 * 100vw);
		border: none;
		font-size: calc(16 / 375 * 100vw);
		// color: #FFF;
		// text-shadow: 1px 1px 0px rgb(255 255 255 / 80%);
    .pagecode-index & {
			height: calc(234 / 375 * 100vw);
      max-height: calc(#{var(--viewHeight)} - #{var(--headerHeight)});
      min-height: 320px;
			border: none;
			font-size: calc(16 / 375 * 100vw);
			text-align: center;
    }
  }
  &__inner {
    display: flex;
		flex-direction: column;
		justify-content: center;
    width: 1000px;
    height: 100%;
		max-width: 100%;
		margin: 0 auto;
    position: relative;
    z-index: 20;
		.pagecode-index & {
			width: 100%;
		}
  }
  &__catch {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    position: relative;
		.pagecode-index & {
			text-transform: uppercase;
		}
  }
	&__subTxt {
		margin-top: 2em;
		font-size: 1.4rem;
		@include base.mq('m') {
			margin-top: 2.6rem;
			font-size: calc(10 / 375 * 100vw);
			.u-pcBlock {
				display: none;
			}
			.u-spBlock {
				display: block;
			}
		}
	}
}

.webs {
	
	&.l-contents__block {
	  width: 100%;
		font-family: base.$en_font;
  	font-size: 2.6rem;
  	font-weight: 600;
  	line-height: calc(40 / 26);
  	letter-spacing: 0.1em;
  	text-align: center;
		color: #333;
	  margin-bottom: 60px;
	  
	  &.child {
	    font-size: 2.4rem;
	  }
	  
	  @include base.mq('m') {
	    margin-bottom: 30px;
	  }
	}
	
  
  &__catch {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    position: relative;
		.pagecode-index & {
			text-transform: uppercase;
		}
  }
	&__subTxt {
		margin-top: 2em;
		font-size: 1.4rem;
		@include base.mq('m') {
			margin-top: 2.6rem;
			font-size: calc(10 / 375 * 100vw);
			.u-pcBlock {
				display: none;
			}
			.u-spBlock {
				display: block;
			}
		}
	}
}

.topSlider {
	height: 100%;
	div {
		width: 100%;
		height: 100%;
	}
	
	img {
		object-fit: cover;
		width: 100%;
		height: 100%
	}
}
