@charset "utf-8";
@use '../foundation/base' as base;
@use '../foundation/mixin' as mixin;

/*-------------------
	gnav
-------------------*/
.l-gnav {
  max-width: 100%;
	.l-footer__nav & {
		display: inline-block;
	}
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
		height: base.$gnav_height;
		max-height: 100%;
		font-family: base.$en_font;
		font-size: 1.3rem;
		font-weight: 600;
		text-transform: capitalize;
		line-height: 1.6;
		letter-spacing: 0;
		color: base.$black;
    .l-footer__nav & {
      display: block;
			font-family: inherit;
      font-size: inherit;
			font-weight: inherit;
			color: inherit;
    }
    @include base.mq('m') {
      flex-direction: column;
			height: auto;
      font-size: 1.4rem;
			letter-spacing: 0.1em;
    }
  }
  &__listItem {
    padding: 0 calc(calc(50 / 13 * 1em) - 1em);
    .l-footer__nav & {
      padding: 0;
			&:not(:last-child) {
				margin-bottom: 12px;
			}
    }
		&--logo {
			display: none;
			.l-header__inner & {
				display: inline-block;
				height: 100%;
				@include mixin.transition;
			}
			.l-header__inner.fixed & {
				height: 90%;
			}
		}
    @include base.mq('m') {
      width: 100%;
			padding: 0;
      text-align: center;
      &:not(:last-child) {
        margin-bottom: calc(17px - 1em);
      }
    }
		@include base.mq('xs') {
			.l-footer__nav & {
				&:not(:last-child) {
					margin-bottom: 16px;
				}
			}
		}
  }
  &__link {
    display: inline-flex;
		height: 100%;
    padding: 0.5em 0.5em;
    position: relative;
    &:hover,
    &.current,
    .c-accordion.open & {
      opacity: mixin.$hoverOpacity;
    }
    .l-footer__nav & {
      padding: 0;
			text-decoration: underline;
      &:hover,
      &.current {
				text-decoration: none;
        opacity: 1;
      }
    }
  }
}
