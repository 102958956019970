@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;

/*** btn ***/
.c-btnWrap {
  @include mixin.btnWrap();
  &--more {
    @include mixin.btnWrap('more');
  }
  &--mail {
    @include mixin.btnWrap('mail');
  }
}
.c-btn {
  $block: #{&};
  @include mixin.btn;
  &--red {
    @include mixin.btn('red');
  }
}
