@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;

// c-accordion
.c-accordion {
  $block: #{&};
  position: relative;
  &__parent {
    &--spBtn {
      display: none;
    }
    @include base.mq('m') {
      position: relative;
      &--spBtn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid base.$black;
        background: none;
        & > span {
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6px 0 6px;
          border-color: base.$black transparent transparent transparent;
        }
      }
    }
  }
  &__list {
    width: 262px;
    height: 0;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100%);
    left: 0;
    @include mixin.transition;
    #{$block}.open & {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
    @include base.mq('m') {
      width: 100%;
      position: relative;
      top: 0;
    }
  }
  &__listItem {
    background: base.$gray-66;
    font-size: calc(14 / 16 * 1em);
    &:not(:last-child) {
      border-bottom: 1px dashed #bfbfbf;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    padding: 0.55em 1.7em;
    &:hover,
    &.current {
      opacity: 1;
      background: base.$black;
      color: #fff;
    }
  }
}
