@charset "utf-8";
@use '../../foundation/base' as base;
@use '../../foundation/mixin' as mixin;

/*-------------------
	リスト
-------------------*/

// デフォルト
.c-list {
  // &:not(:last-child) {
  //   margin-bottom: 1em;
  // }
	.u-txt + &,
	& + .u-txt {
		margin-top: 2em;
	}
  &__item {
    padding-left: 1em;
    position: relative;
    &::before {
      content: '・';
      position: absolute;
      top: 0;
      left: 0;
    }
    &:not(:last-child) {
      margin-bottom: 0.4em;
    }
    @include base.mq() {
      margin-bottom: 0.1em;
    }
  }
}
.c-ol {
	padding-left: 2em;
	list-style-type: decimal;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
	&__item {
		list-style: inherit;
	}
}
.c-note {
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  &.bold {
    font-weight: 700;
  }
  &__item {
    padding-left: 1em;
    position: relative;
    &::before {
      content: '※';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.c-flexList {
  display: flex;
  justify-content: space-between;
  @include base.mq() {
    display: block;
  }
  &__left,
  &__right {
    width: 45%;
    @include base.mq() {
      width: 100%;
    }
  }
  &__right {
    @include base.mq() {
      margin-top: 2.5em;
    }
  }
}

.c-imgList {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: calc(340 / 1050 * 100%);
    margin-bottom: calc(15 / 1050 * 100%);
    &:not(:nth-child(3n)) {
      margin-right: calc(15 / 1050 * 100%);
    }
    @include base.mq() {
      width: 48%;
      margin-bottom: 4%;
      &:not(:nth-child(3n)) {
        margin-right: 0;
      }
      &:not(:nth-child(2n)) {
        margin-right: 4%;
      }
    }
  }
}

.c-imgTxtBox {
	$block: #{&};
	display: flex;
	justify-content: space-between;
	align-items: center;
	&--reverse {
		@extend #{$block};
		flex-direction: row-reverse;
	}
	@include base.mq() {
		&, &--reverse {
			flex-direction: column;
			align-items: flex-start;
		}
	}
	&__img {
		flex: 0 0 auto;
	}
}

.c-snsList {
	display: inline-flex;
	&__item {
		width: 16px;
		height: 16px;
		&:not(:last-child) {
			margin-right: 20px;
		}
	}
	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}

// table
.c-tableList {
  $block: #{&};
	width: 100%;
	line-height: calc(23 / 14);
	&:not(:last-child) {
		margin-bottom: 2em;
	}
  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed base.$gray-bb;
  }
  &__label,
  &__detail {
    padding: calc(12 / 14 * 1em) 0;
  }
  &__label {
    flex: 0 0 auto;
    width: 7em;
  }
  &__detail {
    width: calc(100% - 7em);
  }
}


/*-------------------
	membership
-------------------*/
.c-hashLinkList {
	display: flex;
	flex-wrap: wrap;
	@include base.mq('xs') {
		flex-direction: column;
	}
	&__item {
		display: block;
		width: calc(440 / 900 * 100%);
		padding: 20px 0 30px;
		border: 1px solid base.$gray-e4;
		background: base.$gray-e7;
		text-align: center;
		line-height: calc(26 / 14);
		&:nth-child(odd) {
			margin-right: calc(20 / 900 * 100%);
		}
		&:not(:nth-last-child(-n+2)) {
			margin-bottom: calc(20 / 900 * 100%);
		}
		@include base.mq('m') {
			padding: 20px 0.5em 30px;
			font-size: 1.2rem;
		}
		@include base.mq() {
			padding: 10px 0.5em 15px;
			font-size: inherit;
			line-height: 2;
		}
		@include base.mq('xs') {
			width: 100%;
			&:nth-child(odd),
			&:not(:nth-last-child(-n+2)) {
				margin: 0 auto;
			}
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
	&__label {
		margin-bottom: 20px;
		font-size: calc(18 / 14 * 1em);
		font-weight: 700;
		@include base.mq() {
			margin-bottom: 5px;
			font-size: 1.4em;
		}
	}
}
.c-benefitsList {
	line-height: calc(26 / 14);
	.u-txt + .u-txt {
		margin-top: 1em;
	}
	&__item {
		&:not(:last-child) {
			padding-bottom: 40px;
			margin-bottom: 40px;
			position: relative;
			&::after {
				content: '';
				width: 150px;
				height: 1px;
				background: base.$gray-cc;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
			}
		}
		.c-imgTxtBox {
			align-items: flex-start;
			&__img {
				width: calc(288 / 900 * 100%);
			}
			&__detail {
				width: calc(582 / 900 * 100%);
			}
		}
		@include base.mq() {
			&:not(:last-child) {
				padding-bottom: 30px;
				margin-bottom: 30px;
				&::after {
					width: 100px;
				}
			}
			.c-imgTxtBox {
				&__img,
				&__detail {
					width: 100%;
				}
				&__img {
					width: calc(200 / 375 * 100vw);
					margin: 0 auto 20px;
					text-align: center;
				}
			}
		}
	}
}
.c-cardList {
	$block: #{&};
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	font-size: 10px;
	line-height: 2.2;
	@include base.mq('xs') {
		flex-direction: column;
		align-items: center;
	}
	&__item {
		width: calc(288 / 900 * 100%);
		background: base.$gray-fa;
		&:not(:nth-child(3n)) {
			margin-right: calc(18 / 900 * 100%);
		}
		&:not(:nth-last-child(-n+3)) {
			margin-bottom: calc(18 / 900 * 100%);
		}
		@include base.mq('xs') {
			width: calc(288 / 375 * 100vw);
			max-width: 288px;
			&:not(:nth-child(3n)),
			&:not(:nth-last-child(-n+3)) {
				margin: 0 auto;
			}
			&:not(:last-child) {
				margin-bottom: 15px;
			}
		}
	}
	&__head {
		position: relative;
	}
	&__tag {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 0 10px;
		background: base.$gray-70;
		font-size: 1.2em;
		line-height: calc(25 / 12);
		text-align: center;
		color: #FFF;
		position: absolute;
		bottom: 0;
		right: 0;
	}
	&__detail {
		padding: 10px 15px 15px;
	}
	&__label {
		margin-bottom: 10px;
		font-size: 1.4em;
		line-height: calc(22 / 14);
	}
	&__terms {
		&--label, &--txt {
			display: inline-block;
		}
	}
	&__andMore {
		padding-right: 16px;
		margin-top: 14px;
		text-align: right;
		text-decoration: underline;
		position: relative;
		&::after {
			content: '';
			width: 6px;
			height: 9px;
			background: url(../img/common/arrow_bread.svg) no-repeat;
			background-size: auto 100%;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(0, -50%);
			@include mixin.transition-trf;
		}
		#{$block}__item:hover & {
			text-decoration: none;
			&::after {
				transform: translate(3px, -50%);
			}
		}
	}
}
.c-stepList {
	display: flex;
	justify-content: center;
	line-height: calc(24 / 14);
	text-align: center;
	&__item {
		width: 188px;
		max-width: calc(50% - 30px);
		&:not(:last-child) {
			margin-right: 60px;
			position: relative;
			&::after {
				content: '';
				width: 16px;
				height: 16px;
				background: url(../img/common/arrow_step.svg) no-repeat;
				background-size: auto 100%;
				position: absolute;
				top: 34px;
				right: -38px;
			}
		}
	}
	&__detail {
		margin-top: 34px;
		&--label {
			margin-bottom: 20px;
			font-family: base.$en_font;
			font-size: calc(20 / 14 * 1em);
			font-weight: 600;
			letter-spacing: 0.1em;
			color: base.$black;
		}
		&--txt {
			text-align: left;
		}
	}
}


/*-------------------
	school
-------------------*/
.c-schoolList {
	$block: #{&};
	line-height: calc(26 / 14);
	& + .u-txt {
		margin-top: 20px;
	}
	@include base.mq() {
		font-size: 1.2em;
		line-height: calc(22 / 12);
	}
	&__head, &__item {
		display: flex;
		justify-content: space-between;
	}
	&__head {
		border-bottom: 1px solid base.$gray-bb;
		font-weight: 700;
		@include base.mq() {
			display: none;
		}
	}
	&__item {
		border-bottom: 1px dashed base.$gray-bb;
		@include base.mq() {
			flex-direction: column;
			padding: 16px 0 15px;
		}
	}
	&__left, &__right {
		padding: 8px 20px 10px;
		@include base.mq() {
			padding: 0;
		}
	}
	&__left {
		width: calc(436 / 900 * 100%);
		#{$block}.class & {
			width: calc(320 / 900 * 100%);
		}
		@include base.mq() {
			&, #{$block}.class & {
				width: 100%;
			}
		}
	}
	&__right {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: calc(464 / 900 * 100%);
		#{$block}.class & {
			width: calc(580 / 900 * 100%);
		}
		@include base.mq() {
			&, #{$block}.class & {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	&__area {
		width: 100%;
	}
	&__sns {
		flex: 0 0 auto;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: calc(132 / 424 * 100%);
		& > a {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 24px;
			height: 24px;
			&:not(:last-child) {
				margin-right: 30px;
			}
			@include base.mq() {
				width: 20px;
				height: 20px;
				&:not(:last-child) {
					margin-right: 20px;
				}
			}
		}
	}
}


/*-------------------
	blog
-------------------*/
.c-catList {
	&__item {
		&:not(:last-child) {
			margin-bottom: 12px;
		}
	}
	&__link {
		display: inline-block;
		padding-left: 16px;
		text-decoration: underline;
		position: relative;
		&::before {
			content: '';
			width: 6px;
			height: 9px;
			background: url(../img/common/arrow_bread.svg) no-repeat;
			background-size: auto 100%;
			position: absolute;
			top: 12px;
			left: 0;
			transform: translateX(0);
			@include mixin.transition-trf;
		}
		&:hover {
			text-decoration: none;
			&::before {
				transform: translateX(5px);
			}
		}
	}
}
