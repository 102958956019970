@charset "utf-8";
@use '../foundation/base' as base;
@use '../foundation/mixin' as mixin;

/*-------------------
	header
-------------------*/
.l-header {
  width: 100%;
  height: base.$gnav_height;
	margin: 20px 0;
  position: relative;
	.pagecode-index & {
		margin: 0;
		position: absolute;
		top: 40px;
		left: 0;
	}
  @include base.mq('m') {
    // height: base.$gnav_heightSP;
		display: none;
  }
  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: base.$gnav_height;
    background: rgba(#fff, 0);
		font-family: base.$en_font;
		font-size: 1.3rem;
		font-weight: 600;
		text-transform: capitalize;
		line-height: 1.6;
		letter-spacing: 0;
		color: base.$black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 500;
    @include mixin.transition;
    &.fixed {
      position: fixed;
			background: rgba(#fff, 0.8);
      @include base.mq('m') {
        position: absolute;
      }
    }
    @include base.mq('m') {
      justify-content: flex-start;
      height: base.$gnav_heightSP;
      padding: 1em base.$spYohaku;
    }
  }
  &__nav {
		height: 100%;
    @include base.mq('m') {
      display: none;
    }
  }
}
