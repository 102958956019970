@charset "utf-8";
@use 'foundation/base' as base;
@use 'foundation/mixin' as mixin;

.p-about {
	margin-bottom: 74px;
	@include base.mq() {
		margin-bottom: base.$spMargin;
	}
}
