@charset "utf-8";
@use 'base' as base;
@use 'mixin' as mixin;

/* settings
--------------------------- */
$animation-duration: 0.6s;
$animation-timing-function: ease-out;

// transition-delay
@mixin transition-delay($last: 23, $time: 0.08s) {
  @for $i from 1 through $last {
    &:nth-child(#{$i}) {
      transition-delay: calc(#{$time} + calc(#{$i}) * #{$time});
    }
  }
}

/* inview
--------------------------- */
//フェードイン
.FadeIn {
  opacity: 0;
  transition: opacity $animation-duration $animation-timing-function;
  transition-delay: 0.3s;
  &--Start {
    opacity: 1;
  }
}

//下からフェードイン
.FadeIn_Up {
  opacity: 0;
  transform: translate(0, 100px);
  transition: opacity $animation-duration $animation-timing-function,
    transform $animation-duration $animation-timing-function;
  transition-delay: 0.3s;
  &--Start {
    transform: translate(0, 0);
    opacity: 1;
  }
}

//左からフェードイン
.FadeIn_LeftRight {
  opacity: 0;
  transform: translate(-50px, 0);
  transition: opacity $animation-duration $animation-timing-function,
    transform $animation-duration $animation-timing-function;
  transition-delay: 0.3s;
  &--Start {
    transform: translate(0, 0);
    opacity: 1;
  }
}

//右からフェードイン
.FadeIn_RightLeft {
  opacity: 0;
  transform: translate(50px, 0);
  transition: opacity $animation-duration $animation-timing-function,
    transform $animation-duration $animation-timing-function;
  transition-delay: 0.3s;
  &--Start {
    transform: translate(0, 0);
    opacity: 1;
  }
}

//ズームイン
.ZoomIn {
  opacity: 0;
  transform: scale(0.5, 0.5);
  transition: opacity $animation-duration $animation-timing-function, transform $animation-duration $animation-timing-function;
  transition-delay: 0.3s;
  &--Start {
    transform: scale(1, 1);
    opacity: 1;
  }
}

.Blur {
  opacity: 0;
  filter: blur(20px);
  transition: opacity $animation-duration $animation-timing-function, filter $animation-duration $animation-timing-function;
  transition-delay: 0.3s;
  &--Start {
    opacity: 1;
    filter: blur(0px);
  }
}

.pop-txt {
  opacity: 0;
  position: relative;
  bottom: -8px;
  @include mixin.transition;
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      transition-delay: calc(#{$i} * 0.1s);
    }
  }
  .color--blue & {
    &:nth-child(1) {
      transition-delay: 0.7s;
    }
    &:nth-child(2) {
      transition-delay: 0.8s;
    }
  }
  .mv__catch--main &:last-child {
    transition-delay: 0.9s;
  }
  .start--txt & {
    opacity: 1;
    bottom: 0;
  }
}

/* others
--------------------------- */
// loading
@keyframes donuts {
  0% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }
  100% {
    width: 6px;
    height: 6px;
    opacity: 0.2;
    margin-left: 7px;
  }
}

// scrollBtn
@keyframes scrollBtn {
  0% {
    transform: scale(1, 0);
    transform-origin: top center;
  }
  20% {
    transform: scale(1, 1);
    transform-origin: top center;
  }
  40% {
    transform: scale(1, 1);
    transform-origin: bottom center;
  }
  60% {
    transform: scale(1, 0);
    transform-origin: bottom center;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: bottom center;
  }
}

// fuwafuwa
@keyframes fuwafuwa {
  0% {
    transform: translateY(0);
		opacity: 0.5;
  }
  100% {
    transform: translateY(-0.6em);
		opacity: 1;
  }
}
