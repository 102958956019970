@use 'base' as base;

// transition
@mixin transition($timing: ease-out, $duration: 0.3s) {
  transition: all $timing $duration;
}
@mixin transition-trf($timing: ease-out, $duration: 0.3s) {
  transition: transform $timing $duration;
}

// opacity / @include opacityBtn(0.7);
$hoverOpacity: 0.7;
@mixin opacityBtn($opacity: 0.7) {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

// 見出し
@mixin heading01($flg: 'center', $fsz: 2.0rem) {
	padding-bottom: 0.5em;
	font-family: base.$en_font;
  font-size: $fsz;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.1em;
  color: base.$black;
  position: relative;
  z-index: 1;
	&::after {
		content: '';
		width: 3em;
		height: 1px;
		background: base.$gray-bb;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	@if $flg == 'center' {
		text-align: center;
	}
  @include base.mq() {
    font-size: calc(20 / 14 * 1em);
  }
}
@mixin heading02($fsz: 1.8rem) {
  font-size: $fsz;
  font-weight: 700;
  line-height: calc(26 / 18);
  color: inherit;
  @include base.mq() {
    font-size: 1.6rem;
		text-align: center;
  }
}
@mixin heading03($fsz: 2.0rem) {
	font-family: base.$en_font;
  font-size: $fsz;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.1em;
  color: base.$black;
	text-align: center;
  @include base.mq() {
    font-size: 1.4em;
  }
}

// btn
@mixin btnWrap($flg: 'normal') {
  width: 100%;
	max-width: 350px;
  margin: 32px auto 0;
  font-family: inherit;
  letter-spacing: 0;
}
@mixin btn($flg: 'gray') {
  $block: #{&};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
	height: auto;
	padding: 11px 0.5em 13px;
	border: none;
	background: base.$gray-aa;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: inherit;
  line-height: 1.4;
  color: #fff;
  @include transition;
  z-index: 1;
  @include opacityBtn();
	@include base.mq() {
		padding: 9px 0.5em 10px;
		font-size: 12px;
	}
}

// clear fix
@mixin clearFix {
  &::after {
    content: '';
    display: block;
    clear: both;
    @include base.mq() {
      display: none;
    }
  }
}

// object-fit
@mixin ofi {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// hoverScale
@mixin hoverScale {
  & > img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    object-fit: cover;
    @include transition-trf;
  }
}
@mixin hoverScale--hover {
  & > img {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
